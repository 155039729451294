// Action types
export const UPDATE_NOTIFICATION_COUNT = 'app/notifications/UPDATE_NOTIFICATION_COUNT';

// Initial state
const initialState = {
    notificationCount: 0,
};

// Reducer
export default function notificationsReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_NOTIFICATION_COUNT:
            return { ...state, notificationCount: payload };
        default:
            return state;
    }
}

// Actions creators
export const updateNotificationCount = (count) => ({
    type: UPDATE_NOTIFICATION_COUNT,
    payload: count,
});